import apiRequest from '../../js/helpers/apiRequest.js';
import parseUsers from '../../js/helpers/parseUsers.js';

import { SET_MANAGERS } from '../mutations.js';

export default {
  state: {
    managers: [],
  },

  getters: {},

  mutations: {
    [SET_MANAGERS](state, managers) {
      state.managers = managers;
    },
  },

  actions: {
    async getManagers({ commit }) {
      try {
        const res = await apiRequest('/users', 'GET', null, true);

        if (res.managers) {
          commit(
            SET_MANAGERS,
            res.managers
              .map(parseUsers)
              .sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      } catch (error) {
        console.log('getManagers:', error);
      }
    },
  },
};
