export const SET_APP_READY = 'SET_APP_READY';

export const SET_USER_INFO = 'SET_USER_INFO';

export const SET_DIRECTIONS = 'SET_DIRECTIONS';
export const SET_CULTURES = 'SET_CULTURES';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const SET_MANAGERS = 'SET_MANAGERS';
export const SET_STATUSES = 'SET_STATUSES';
export const SET_FILTERS_LOAD = 'SET_FILTERS_LOAD';

export const SET_FILTER_STATUS = 'SET_FILTER_STATUS';

export const SET_OBJECTS = 'SET_OBJECTS';
export const SET_OBJECT_INFO = 'SET_OBJECT_INFO';
export const SET_LAST_OBJECT_STATUS = 'SET_LAST_OBJECT_STATUS';
export const SET_OBJECT_HISTORY_STATUS = 'SET_OBJECT_HISTORY_STATUS';
export const SET_OBJECT_SWON_AREAS = 'SET_OBJECT_SWON_AREAS';
export const SET_OBJECTS_LOADING = 'SET_OBJECTS_LOADING';
export const SET_OBJECT_LOADING = 'SET_OBJECT_LOADING';
export const SET_POINTS = 'SET_POINTS';
export const SET_PAGINATION_INFO = 'SET_PAGINATION_INFO';
