<template>
  <a-config-provider :locale="ru_RU">
    <div id="app">
      <router-view></router-view>
      <!-- <winter-mode></winter-mode> -->
    </div>
  </a-config-provider>
</template>

<script>
import { mapActions } from 'vuex';
import ru_RU from 'ant-design-vue/lib/locale-provider/ru_RU.js';
import isTokenExpired from './js/helpers/isTokenExpired.js';
import removeTokenFromLocalStorage from './js/helpers/removeTokenFromLocalStorage.js';

// import WinterMode from './components/WinterMode.vue';

export default {
  name: 'App',

  // components: {
  // WinterMode
  // },

  data() {
    return {
      ru_RU,
    };
  },

  created() {
    if (localStorage.getItem('access_token') && !isTokenExpired()) {
      Promise.all([
        this.getUserInfo(),
        this.getOptions(),
        this.getManagers(),
      ]).then(() => {
        this.$store.commit('app/SET_APP_READY');
      });
    } else {
      removeTokenFromLocalStorage();
      this.$router.replace('/authorization');
    }
  },

  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      getOptions: 'filters/getOptions',
      getManagers: 'managers/getManagers',
    }),
  },
};
</script>

<style lang="scss"></style>
